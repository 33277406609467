import unhead_9LbjuvHvsD from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4._xtrbhgi7tz5qhabe5fuedfaqea/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_z2eYMUllbe from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4._xtrbhgi7tz5qhabe5fuedfaqea/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Nb6pURvR79 from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4._xtrbhgi7tz5qhabe5fuedfaqea/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_pEuRueaFTf from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4._xtrbhgi7tz5qhabe5fuedfaqea/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_3hU3TuRAEo from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.3_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src856064189/src/mosaic-land/.nuxt/components.plugin.mjs";
import prefetch_client_4WzHO37hnQ from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4._xtrbhgi7tz5qhabe5fuedfaqea/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/codebuild/output/src856064189/src/mosaic-land/.nuxt/floating-vue.mjs";
import leaflet_runtime_TZgKzdawln from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt3-leaflet@1.0.12_typescript@5.4.3/node_modules/nuxt3-leaflet/dist/runtime/leaflet-runtime.mjs";
import chunk_reload_client_wN7O2TIc92 from "/codebuild/output/src856064189/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@unocss+reset@0.58.9_eslint@8.57.0_floating-vue@5.2.2_sass@1.72.0_typescript@5.4._xtrbhgi7tz5qhabe5fuedfaqea/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/codebuild/output/src856064189/src/mosaic-land/plugins/auth.ts";
import bootstrap_client_Uyd73t1ylZ from "/codebuild/output/src856064189/src/mosaic-land/plugins/bootstrap.client.ts";
import click_outside_client_QHauXawCCo from "/codebuild/output/src856064189/src/mosaic-land/plugins/click-outside.client.ts";
import leaflet_client_7dEOJA4hbk from "/codebuild/output/src856064189/src/mosaic-land/plugins/leaflet.client.ts";
import moment_NWWiRYbNqj from "/codebuild/output/src856064189/src/mosaic-land/plugins/moment.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src856064189/src/mosaic-land/plugins/sentry.ts";
export default [
  unhead_9LbjuvHvsD,
  router_z2eYMUllbe,
  payload_client_Nb6pURvR79,
  check_outdated_build_client_pEuRueaFTf,
  plugin_vue3_3hU3TuRAEo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4WzHO37hnQ,
  floating_vue_EIcJ7xiw0h,
  leaflet_runtime_TZgKzdawln,
  chunk_reload_client_wN7O2TIc92,
  auth_vT9JWWT9pN,
  bootstrap_client_Uyd73t1ylZ,
  click_outside_client_QHauXawCCo,
  leaflet_client_7dEOJA4hbk,
  moment_NWWiRYbNqj,
  sentry_3AyO8nEfhE
]